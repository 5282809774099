<template>
    <div>
        <Datatable :headers="headers" :requireActions="true" :requireStatusCol="false" :hover="true">
            <Datarow 
                v-for="row in renderRows" 
                v-bind:key="row.ID" 
                :dataRow="row" 
                :showCrudActions="true"
                :requireStatusCol="false"
                :statusPropName="'status'"
                @onSelectChange="onSelectSupplier"
                :selectedSuppliers="getSelectedSuppliers"
            >
                <CrudActions 
                    :itemID="row.ID" 
                    :requireE="false" 
                    :requireM="false"
                    :requireV="true"
                    :callbackCrudAction="handleCrudAction"
                />   
            </Datarow>
        </Datatable>
        <div class="paginator">
            <Paginator 
                :numItems="numRecords" 
                :itemsPerPage="recordsPerPage"
                :selectedPage="currentSelectedPage"
                @handleClickPage="handleClickPageDetails"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, onBeforeMount, toRefs, reactive, computed } from 'vue';
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import { formatToDatatable } from "../../../common/formaterToDatatable";
import ApiService from "@/core/services/ApiService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import CrudActions from "../../../components/c-module-master/crud-actions.vue";
import Paginator from "../../../components/c-paginator/Paginator.vue"

export default defineComponent({
    name: "history-receptions",
    components: {
        Datatable
        , Datarow
        , CrudActions
        , Paginator
    },
    props: {
        purchaseOrderId: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const messageService = new SwalMessageService();
        const { purchaseOrderId } = toRefs(props);
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false }
            , { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true }
            , { PropName: "receiverName", HeadLabel: "Recepciona", Type: "text", VisibleInGrid: true }
            , { PropName: "receptionDate", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true }
            , { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true }
        ]);
        let details = ref([]);
        let numItemsPaginator = ref(0);
        let itemsPerPage = ref(5);
        let currentPage = ref(1);

        onBeforeMount(async () => {
            if (purchaseOrderId.value) { 
                await getDetails(purchaseOrderId.value);
            }
        });

        //COMPUTEDS

        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(details.value, configTable, "status");
        });

        const numRecords = computed(() => {
            return numItemsPaginator.value;
        });

        const recordsPerPage = computed(() => {
            return itemsPerPage.value;
        });

        const currentSelectedPage = computed(() => {
            return currentPage.value;
        });

        //METHODS

        const getDetails = async(itemId) => {
            let url = `ReceiptPurchaseOrders/GetList/${itemId}`;
            ApiService.query(url).then(res => {
                details.value = res.data.data;
                numItemsPaginator.value = 10;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleClickPageDetails = (page) => {
            currentPage.value = page;
        }

        return {
            headers
            , renderRows
            , numRecords
            , recordsPerPage
            , currentSelectedPage
            , handleClickPageDetails
        }
    },
})
</script>

<i18n>
{
    "es": {
        "TITLE": "Historial de Recepciones"
    }
}
</i18n>
