
import { defineComponent, onBeforeMount, ref, toRefs, computed } from 'vue';
import * as Validations from "yup";
import ModalForm from "../../../components/c-modal-form/ModalFormMaster.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import SelectOption from "../../../components/c-form-elements/SelectOption.vue";
import InputText from "@/components/forms/InputText.vue"
import { InputTextMaskTypes } from '@/core/enums/input-text-mask-types';

export default defineComponent({
    components:{
        ModalForm,
        RowForm,
        SelectOption
        , InputText
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Recepción Total"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        purchaseOrderId: {
            type: String,
            required: true
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
    },
    emits: ["saved-response"],
    setup(props, { emit }) {
        const { mode, currentOpenedItem, purchaseOrderId } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref();
        let txtName = ref("");
        let controller = "ReceiptPurchaseOrders";
        let warehouses = ref([]);

        const validationSchema = Validations.object().shape({
            warehouseId: Validations.string().required().label("Almacén"),
            freight: Validations.number().required().label("Importe del Flete")
        });

        let currentLineId = ref("");

        //HOOKS
        onBeforeMount(async() => {
            await getComboOptions("Warehouses", warehouses);
        });

        //VARAIBLES COMPUTADAS
        const getCurrentLineId = computed(() => {
            return currentLineId.value;
        });

        const initialValues = computed(() => {
            let article: { productName: string, quantityDetail: number, quantityReceived: number } = {
                productName: ''
                , quantityDetail: 0
                , quantityReceived: 0
            };
            if(mode.value === "N" && currentOpenedItem.value) {
                article.productName = currentOpenedItem.value.productName;
                article.quantityDetail = currentOpenedItem.value.quantityDetail;
                article.quantityReceived = currentOpenedItem.value.quantityReceived;
            }
            
            return article;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboWarehouses = computed(() => warehouses.value );

        //FUNCIONES
        const initDefaults = () => {
            modalForm.value.$refs.form.resetForm(); 
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            ApiService.put(`${controller}/${purchaseOrderId.value}/EndCapture`, item).then(res => {
                if(res.status == 204){
                    modalForm.value.closeModal();
                    messageService.success("La Orden de Compra fue recepcionada parcialmente.");
                    emit("saved-response", true);
                    // if(props.fnCallBackPersistenceNotifier) {
                    //     props.fnCallBackPersistenceNotifier(item);
                    // }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
                 emit("saved-response", false);
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${purchaseOrderId.value}`;
            item.Id = currentOpenedItem.value.id;

            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboOptions = async(resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        return {
            editForm,
            modalForm,
            txtName,
            validationSchema
            , maskMoney: InputTextMaskTypes.Money,
            //Variables computadas
            initialValues,
            readOnly,
            isEditing,
            getCurrentLineId,
            comboWarehouses,

            //Funciones
            handleCrudAction,
            initDefaults
        }
    },
})
